/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { watch, ref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { userGql } from "@/api/onboarding/user";
import { User_user_User } from "@/api/onboarding/__generated__/User";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useStore } from "vuex";
import debounce from "lodash.debounce";
import { makeSimpleStore } from "@/shared/utils/simpleStoreUtils";
import { isCorporateSite } from "@/shared/utils/config";
import { apiErrorCodes } from "@/shared/utils/constants";
/**
 * Make the forceRefresh as a shared state since there are multiple components
 * and screens that uses the [useUpdateUserInfo().update] function. Each update
 * should be able to decide whether to enable or disable forced refresh
 */
const forceRefreshStore = makeSimpleStore<{ forceRefresh: boolean }>({
  forceRefresh: false,
});

export function useUpdateUserInfo() {
  const store = useStore();
  /**
   * Only query for user if authenticated
   */
  const user = useQuery(userGql, null, {
    enabled: store.state.userInfo.isAuthenticated,
    fetchPolicy: "no-cache",
  });
  const isReloading = ref(false);
  const companyId = ref(null);

  watch([user.result], ([newValue]) => {
    /** store previous status before
     * storing the updated one upon
     * store.dispatch
     * */
    const prevStatus = store.state.userInfo.user.internalVerificationStatus;
    const prevPage = store.state.userInfo.user.currentVerificationPage;
    const prevCompanyStatus =
      store.state.userInfo.user.companyVerificationStatus;
    const prevPersonalStatus =
      store.state.userInfo.user.personalVerificationStatus;
    const prevPendingEmail = store.state.userInfo.user.pendingEmail;
    const prevEmail = store.state.userInfo.user.email;
    const prevEmailStatus = store.state.userInfo.user.isEmailConfirmed;

    const prevPendingPhone = store.state.userInfo.user.pendingPhone;
    const prevPhone = store.state.userInfo.user.phone;
    const prevPhoneStatus = store.state.userInfo.user.isPhoneConfirmed;

    const parsedGqlResponse = parseGqlResponse<User_user_User>(
      "User",
      newValue,
      apiErrorCodes.INVALID_AUTH_TOKEN
    );
    /** store status based from response */
    store.dispatch("updateUserInfo", {
      ...parsedGqlResponse?.data,
    });

    if (isCorporateSite) {
      companyId.value = store.state.userInfo.user.apiCompanyId;
      store.state.userInfo.company.companyName =
        parsedGqlResponse?.data?.companyName;
    }

    console.log("forceRefresh.value", forceRefreshStore.forceRefresh);

    /** only force refresh if specified and if prevStatus
     * is not the same with the currently stored status
     */
    if (
      (forceRefreshStore.forceRefresh ?? false) &&
      (store.state.userInfo.user.internalVerificationStatus != prevStatus ||
        store.state.userInfo.user.currentVerificationPage != prevPage ||
        store.state.userInfo?.user?.companyVerificationStatus !=
          prevCompanyStatus ||
        store.state.userInfo.user.personalVerificationStatus !=
          prevPersonalStatus ||
        store.state.userInfo.user.email != prevEmail ||
        store.state.userInfo.user.isEmailConfirmed != prevEmailStatus ||
        store.state.userInfo?.user?.pendingEmail != prevPendingEmail ||
        store.state.userInfo?.user?.isPhoneConfirmed != prevPhoneStatus ||
        store.state.userInfo?.user?.phone != prevPhone ||
        store.state.userInfo?.user?.pendingPhone != prevPendingPhone)
    ) {
      console.log("page reload trigerred by:", {
        internalVerificationStatus:
          store.state.userInfo.user.internalVerificationStatus != prevStatus,
        currentVerificationPage:
          store.state.userInfo.user.currentVerificationPage != prevPage,
        companyVerificationStatus:
          store.state.userInfo?.user?.companyVerificationStatus !=
          prevCompanyStatus,
        personalVerificationStatus:
          store.state.userInfo.user.personalVerificationStatus !=
          prevPersonalStatus,
      });
      window.location.reload();
      /** manual toggling of ref when page reload is called;
       * added debounce since if ref is used for UI (i.e. loading indicators)
       * it would have some delay before updating all related components
       */
      debounce(() => (isReloading.value = false), 500);
    } else {
      /** force to toggle isReloading if forceRefresh is not applied */
      isReloading.value = false;
    }
  });

  return {
    user,
    isReloading,
    companyId,
    update: async (newForceRefreshValue?: boolean) => {
      /** manual toggling of ref when update is triggered
       * to detect if page is performing store dispatch/reloading
       */
      forceRefreshStore.forceRefresh = newForceRefreshValue ?? false;
      isReloading.value = true;
      await user.refetch();
    },
  };
}
