import { createStore } from "vuex";
import { userInfo, UserInfoState } from "./userInfo";
import { vuexLocal } from "./vuexLocal";
import {
  kycNavigation,
  KycNavigationState,
} from "@/corporate/store/kycNavigation";
import {
  questionnaireData,
  QuestionnaireDataState,
} from "@/corporate/store/questionnaireData";
import {
  questionnaireNavigation,
  QuestionnaireNavigationState,
} from "@/corporate/store/questionnaireNavigation";
import { personalQuestionnaireNavigation } from "@/corporate/store/personalQuestionnaireNavigation";
import {
  topUpNavigation,
  TopUpNavigationState,
} from "@/corporate/store/topUpNavigation";
import { logRocketVuexPlugin } from "@/shared/utils/logrocket";
import { PersonalQuestionnaireNavigationState } from "@/shared/types/personalQuestionnaireNavigationSteps";
import createMutationsSharer from "vuex-shared-mutations";

export type RootState = {
  userInfo: UserInfoState;
  kycNavigation: KycNavigationState;
  questionnaireNavigation: QuestionnaireNavigationState;
  questionnaireData: QuestionnaireDataState;
  personalQuestionnaireNavigation: PersonalQuestionnaireNavigationState;
  topUpNavigation: TopUpNavigationState;
};

export default createStore<RootState>({
  modules: {
    userInfo,
    kycNavigation,
    questionnaireData,
    questionnaireNavigation,
    personalQuestionnaireNavigation,
    topUpNavigation,
  },
  plugins: [
    vuexLocal.plugin,
    ...logRocketVuexPlugin,
    createMutationsSharer({
      predicate: [
        "goToNextTopUp",
        "goToPreviousTopUp",
        "setTopUpStatus",
        "setVerifyStatus",
        "setTopUpRedirect",
      ],
    }),
  ],
});
