import { WebSocketLink } from "@apollo/client/link/ws";
import { split } from "@apollo/client/core";
import { getMainDefinition } from "@apollo/client/utilities";
import { getAuthToken } from "@/api/graphqlClient/authLink";
import { config } from "@/shared/utils/config";
import { passThruLink } from "@/api/graphqlClient/passThruLink";
import { get, includes, isObject, isString } from "lodash";
import { makeToast } from "@/shared/utils/toast";
import { i18nTranslate } from "@/plugins/i18n";
import { SubscriptionClient } from "subscriptions-transport-ws";
import {
  hasWebSocketConnectionNotifier,
  noWebSocketConnectionNotifierDebounced,
} from "./connectionNotifier";

let reconnectingCounter = 0;
const reconnectingCounterThreshold = 5;

let wsNotConnectedNotificationTimerId;
const wsNotConnectedNotificationTimerDelay = 60000;

export const wsClientContainer = {
  wsClient: null as SubscriptionClient | null,
};

export const generateWsLink = (): WebSocketLink => {
  /**
   * Create a subscription client with ws
   */
  const wsClient = new SubscriptionClient(config.websocketGqlServerUrl, {
    reconnect: true,
    minTimeout: 30000,
    timeout: 120000,
    inactivityTimeout: 30000,
    /**
     * If lazy is true, ws client will only connect on call and not on mount
     */
    lazy: true,
    connectionParams: () => {
      return {
        headers: { authorization: getAuthToken() },
      };
    },
    connectionCallback: (error) => {
      if (error) {
        // this error will be available on logrocket
        console.log(`connectionCallback:error`, error);

        /**
         * Have to check if error is object, error should be an array but returned is object
         * Check if error message includes permission error and show error toast
         */
        if (
          isObject(error) &&
          isString(get(error, "message")) &&
          includes(get(error, "message"), "PermissionError")
        ) {
          makeToast(
            "error",
            i18nTranslate("Error"),
            i18nTranslate(
              "Something went wrong. Please try again later. If the problem persists, please contact us at {contactEmail}",
              {
                contactEmail: config.contactEmail,
              }
            )
          );
        }
      }
    },
  });

  /**
   * Create a ws link from created subscription client
   */
  const wsLink = new WebSocketLink(wsClient);

  wsClient.onDisconnected(() => {
    // lodash's get used here because closedByUser is private type but will still be able to get value using get
    const closedByUser = get(wsClient, "closedByUser");

    /**
     * Do not show error when the connection were close programmatically
     */
    if (!closedByUser) {
      reconnectingCounter = reconnectingCounter + 1;

      if (reconnectingCounter > reconnectingCounterThreshold) {
        noWebSocketConnectionNotifierDebounced();
      }

      if (wsNotConnectedNotificationTimerId == null) {
        // add timeout before showing error, connection might come back right away
        wsNotConnectedNotificationTimerId = setTimeout(() => {
          noWebSocketConnectionNotifierDebounced();
        }, wsNotConnectedNotificationTimerDelay);
      }
    }
  });

  wsClient.onError((error) => {
    console.error(`wsClient.onError`, error);
  });

  wsClient.onConnected(() => {
    /**
     * For debugging, console log websocket messages
     */
    if (wsClient.client && wsClient.client.onmessage) {
      const oldOnMessage = wsClient.client.onmessage;
      wsClient.client.onmessage = (message) => {
        console.log(
          "%cwsClient.client.onmessage",
          "background: #f5f0c6; color: #bf360c",
          message
        );
        oldOnMessage(message);
      };
    }

    if (wsNotConnectedNotificationTimerId != null) {
      clearTimeout(wsNotConnectedNotificationTimerId);
      wsNotConnectedNotificationTimerId = null;
    }

    // only show reconnect notification if disconnect message were shown previously
    if (reconnectingCounter > 0) {
      hasWebSocketConnectionNotifier();
    }
    reconnectingCounter = 0;
  });

  wsClient.onReconnected(() => {
    /**
     * Notify user if connection were re-established
     * There's no need to notify if previously disconnect notification were not sent
     */
    if (wsNotConnectedNotificationTimerId != null) {
      clearTimeout(wsNotConnectedNotificationTimerId);
      wsNotConnectedNotificationTimerId = null;
    }

    // only show reconnect notification if disconnect message were shown previously
    if (reconnectingCounter > 0) {
      hasWebSocketConnectionNotifier();
    }
    reconnectingCounter = 0;
  });

  wsClientContainer.wsClient = wsClient;

  return wsLink;
};

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
export const wsHybridLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);

    console.log("definition", definition);

    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  generateWsLink(),
  passThruLink
);
