import { dateFormats } from "@/shared/utils/date";
import { defaultTo, toNumber, split } from "lodash";
import { regex } from "@/shared/utils/constants";
import { PaymentMode } from "@/shared/store/simpleStore/bankTransferData";

const recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY || "";
const logRocketId = process.env.VUE_APP_LOG_ROCKET_ID || "";

export const config = {
  allowAutomatedTestMode: process.env.VUE_APP_ALLOW_AUTOMATED_TEST_MODE,
  ocrTimeout: process.env.VUE_APP_OCR_TIMEOUT_SECONDS || "60",
  httpGqlServerUrl: process.env.VUE_APP_HTTP_GQL_SERVER_URL || "",
  websocketGqlServerUrl: process.env.VUE_APP_WEBSOCKET_GQL_SERVER_URL || "",
  // #TODO: Change the VUE_APP_MERCHANT_BRAND name into 'OPSY' in .env
  merchantBrand: process.env.VUE_APP_MERCHANT_BRAND || "",
  siteSettings: process.env.VUE_APP_SITE_SETTINGS,
  mobileUploadUrl:
    process.env.VUE_APP_MOBILE_UPLOAD_URL || `${window.location.host}/upload/`,
  generalDateFormat: dateFormats.inverseDot,
  contactEmail: process.env.VUE_APP_MERCHANT_BRAND_CONTACT_EMAIL || "",
  contactNumber: process.env.VUE_APP_MERCHANT_BRAND_CONTACT_NUMBER || "",
  baseURL: process.env.VUE_APP_BASE_URL || window.location.origin,

  // #TODO: Add these on .env for OPSY store link
  appStoreURL: process.env.VUE_APP_APPSTORE_URL,
  googlePlayURL: process.env.VUE_APP_GOOGLEPLAY_URL,
  microsoftStoreURL: process.env.VUE_APP_MICROSOFTSTORE_URL,

  /**
   * LOCALIZATION AND NUMBER FORMATS
   * for number format convert to number, .env returns string
   */
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  minimumFractionDigits: toNumber(
    defaultTo(process.env.VUE_APP_NUMBER_FORMAT_MINIMUM_FRACTION, 0)
  ),
  maximumFractionDigits: toNumber(
    process.env.VUE_APP_NUMBER_FORMAT_MAXIMUM_FRACTION || 8
  ),
  minimumCryptoFractionDigits: toNumber(
    process.env.VUE_APP_CRYPTO_FORMAT_MINIMUM_FRACTION || 6
  ),
  maximumCryptoFractionDigits: toNumber(
    process.env.VUE_APP_CRYPTO_FORMAT_MAXIMUM_FRACTION || 8
  ),

  /**
   * reCAPTCHA
   */
  recaptchaSiteKey,
  isCaptchaEnabled: !!recaptchaSiteKey,

  /**
   * Logrocket
   *
   * have to check against string "true" because .env uses string type
   * only allow logrocket on development/test environment
   */
  logRocketId,
  logRocketEnabled: process.env.VUE_APP_LOG_ROCKET_ENABLE,

  /** Set inactivity timeout in minutes*/
  inactivityWarningTimeoutInMs: 780000, //13 minutes = 780000 milliseconds
  inactivityLogoutTimeoutInMs: 840000, //14 minutes = 840000 milliseconds
  autoLogoutTimeoutInSec: 60,

  /** static links */
  personalPromoPageUrl: process.env.VUE_APP_PERSONAL_PROMO_PAGE_URL,
  corporatePromoPageUrl: process.env.VUE_APP_CORPORATE_PROMO_PAGE_URL,
  personalCabinetUrl: process.env.VUE_APP_PERSONAL_CABINET_URL,
  corporateCabinetUrl: process.env.VUE_APP_CORPORATE_CABINET_URL,
  contactUrl: process.env.VUE_APP_CONTACT_URL,
  privacyPolicyUrl: process.env.VUE_APP_PRIVACY_POLICY_URL,
  termsWebsiteUseUrl: process.env.VUE_APP_TERMS_WEBSITE_USE_URL,
  promotSiteUrl: process.env.VUE_APP_PROMO_SITE_URL,

  /** For Mobile Number Exceptions of Rules */
  // Assign value of env variable
  allowRegisterWithSameMobile:
    process.env.VUE_APP_ALLOW_REGISTER_WITH_SAME_MOBILE,
  whitelistNumbers: process.env.VUE_APP_WHITELIST_NUMBERS,

  allowAnnouncementModal: process.env.VUE_APP_SHOW_ANNOUNCEMENT,

  /** For Toggling Internal Payment with Internal IBAN  */
  allowInternalPaymentIBAN: process.env.VUE_APP_ALLOW_INTERNAL_PAYMENT_IBAN,
  /** For enabling of Post Phase 1 Features
   * (e.g. Email Update Settings, Avatar Upload, Phone Update Settings)
   */
  allowEmailUpdateSettings: process.env.VUE_APP_ALLOW_EMAIL_UPDATE_SETTINGS,
  allowMobileUpdateSettings: process.env.VUE_APP_ALLOW_MOBILE_UPDATE_SETTINGS,
  allowAvatarUploadSettings: process.env.VUE_APP_ALLOW_AVATAR_UPLOAD_SETTINGS,

  // Set countdown timer
  countdownTimer: 60000, //in milliseconds

  // To be used in footer text
  companyName: process.env.VUE_APP_COMPANY_NAME,
  companyWebsite: process.env.VUE_APP_COMPANY_WEBSITE,

  ssoAuthUrl: process.env.VUE_APP_SSO_AUTH_URL,
  ssoTempRedirectUri: process.env.VUE_APP_SSO_TEMP_REDIRECT_URI,

  // To toggle Verification Requirements
  // -- From .env file, value is read as string. Thus == "true" to convert to boolean
  allowVerificationRequirements:
    process.env.VUE_APP_ALLOW_VERIFICATION_REQUIREMENTS,

  // Currencies for Crypto Exchange Feature
  cryptoCurrencies: process.env.VUE_APP_CRYPTO_CURRENCIES,

  // To toggle Fiat Exchange
  allowFiatExchange: process.env.VUE_APP_ALLOW_FIAT_EXCHANGE,

  // Allowed Currencies for Topup Wallet
  showZeroBalanceAccounts: process.env.VUE_APP_SHOW_ZERO_BALANCE_ACCOUNTS,

  // Showing of Max Amount Button
  allowMaxAmount: process.env.VUE_APP_ALLOW_MAX_AMOUNT,

  // Max retry count for `useCaptcha.captchaHandler()`, Default = 5
  captchaMaxRetryCount:
    Number(process.env.VUE_APP_CAPTCHA_MAX_RETRY_COUNT) || 5,

  // Dev variables
  allowDebugConsole: process.env.VUE_APP_ALLOW_DEBUG_CONSOLE,

  // Max file limit to upload
  maxFileLimitToUpload: toNumber(
    process.env.VUE_APP_MAX_FILE_LIMIT_TO_UPLOAD || 10
  ),

  //Max count for personal basic tax information
  maxCountForBasicTaxInfo:
    Number(process.env.VUE_APP_MAX_COUNT_FOR_BASIC_TAX_INFO) || 10,

  //  Generic Upload Document
  allowGenericUploadDocument: process.env.VUE_APP_ALLOW_GENERIC_UPLOAD_DOCUMENT,

  // Allowed Currencies for Topup Wallet
  allowedCurrenciesForTopup:
    process.env.VUE_APP_ALLOWED_CURRENCIES_FOR_TOPUP ?? "GBP,EUR",

  //  Missing Fields Update for Personal Questionnaires
  allowedMissingFieldsUpdateForPersonalQuestionnaires:
    process.env.VUE_APP_ALLOW_UPDATE_PERSONAL_QUESTIONNAIRES_MISSING_FIELDS,

  // Variable to enable Settings Document Tab for non-verified users
  enableSettingsDocTabForNonVerified:
    process.env.VUE_APP_ENABLE_SETTINGS_DOC_TAB_FOR_NON_VERIFIED,

  // Loading timeout
  loadingTimeoutInMs: 10000, //10 seconds

  // EUR Card Transfer Country List
  eurCardTransferCountries:
    process.env.VUE_APP_EUR_CARD_TRANSFER_COUNTRIES ?? "UA,IL",

  // Show Dashboard UAH Shortcuts
  showDashboardUAHBankShortcut:
    process.env.VUE_APP_SHOW_DASHBOARD_UAH_BANK_SHORTCUT ?? "true",

  showDashboardUAHCardShortcut:
    process.env.VUE_APP_SHOW_DASHBOARD_UAH_CARD_SHORTCUT ?? "true",

  showDashboardBankSEPAShortcut:
    process.env.VUE_APP_SHOW_DASHBOARD_SEPA_BANK_SHORTCUT ?? "true",

  // FE permissions to disable certain transfers
  disabledTransfersString: process.env.VUE_APP_DISABLED_TRANSFERS ?? "",

  GTMid: process.env.VUE_APP_GTM_ID ?? "",
  allowGTMPlugin: process.env.VUE_APP_ALLOW_GTM_PLUGIN ?? "",
  allowGTMDebugging: process.env.VUE_APP_ALLOW_GTM_DEBUGGING ?? "",

  // FE permissions for cookie consent
  allowCookieConsent: process.env.VUE_APP_ALLOW_COOKIE ?? "false",
  cookiePolicyUrl: process.env.VUE_APP_COOKIE_POLICY_URL ?? "",

  defaultHistoryItemsOnPage:
    process.env.VUE_APP_DEFAULT_HISTORY_ITEMS_ON_PAGE ?? "150",

  maxFileNameLength: process.env.VUE_APP_MAX_FILE_NAME_LENGTH ?? "100",
};

export const allowDebugConsole = config.allowDebugConsole === "true";
export const allowMaxAmount = config.allowMaxAmount === "true";
export const showZeroBalanceAccounts =
  config.showZeroBalanceAccounts === "true";
export const allowFiatExchange = config.allowFiatExchange === "true";
export const allowVerificationRequirements =
  config.allowVerificationRequirements === "true";
export const allowAnnouncements = config.allowAnnouncementModal === "true";
export const allowEmailUpdateSettings =
  config.allowEmailUpdateSettings === "true";
export const allowMobileUpdateSettings =
  config.allowMobileUpdateSettings === "true";
export const allowAvatarUploadSettings =
  config.allowAvatarUploadSettings === "true";
// check if the same mobile registration is allowed
export const isAllowedSameMobileRegistration =
  config.allowRegisterWithSameMobile === "true";
// Check if Automated Test Mode is true
export const isAllowedAutomatedTestMode =
  config.allowAutomatedTestMode === "true";
//  Check if Generic Upload Document is allowed
export const isAllowedGenericUploadDocument =
  config.allowGenericUploadDocument == "true";
//  Check if Missing Fields Update for Personal Questionnaires is allowed
export const isAllowedMissingFieldsUpdateForPersonalQuestionnaires =
  config.allowedMissingFieldsUpdateForPersonalQuestionnaires == "true";
export const enableSettingsDocTabForNonVerified =
  config.enableSettingsDocTabForNonVerified == "true";
// Show Dashboard UAH Shortcuts
export const showDashboardUAHBankShortcut =
  config.showDashboardUAHBankShortcut == "true";
export const showDashboardUAHCardShortcut =
  config.showDashboardUAHCardShortcut == "true";
export const showDashboardBankSEPAShortcut =
  config.showDashboardBankSEPAShortcut == "true";

// GMT configs
export const allowGTMPlugin = config.allowGTMPlugin == "true";
export const allowGTMDebugging = config.allowGTMDebugging == "true";

// Cookie Consent configs
export const allowCookieConsent = config.allowCookieConsent == "true";

/**
 * Logrocket
 *
 * have to check against string "true" because .env uses string type
 * only allow logrocket on development/test environment
 */
export const isLogRocketEnabled =
  config.logRocketEnabled === "true" && !!config.logRocketId;
// Internal Payment IBAN
export const isAllowedInternalPaymentIBAN =
  config.allowInternalPaymentIBAN === "true";

export const isCorporateSite = config.siteSettings === "corporate";
export const isPrivateSite = config.siteSettings === "private";
export const isSsoLogin = config.siteSettings == "login";

/** ** FULL LIST OF ALL DISABLED TRANSFERS */
/** NOTE: It is easier and more convenient to list only
 * all of the disabled transfers than to list all that is
 * allowed due to the fact that there are a lot of possible
 * combo pair (or transfer variants) */
export const disabledTransfers = split(
  config.disabledTransfersString,
  new RegExp(regex.commaSeparator)
);
/** ** CHECKING FOR SPECIFIC TRANSFER'S FE PERMISSION */
/** NOTE: By default transfers are all permitted otherwise
 * specified as disabled in FE */
export const isTransferDisabled = (type, pair) => {
  if (type && pair) {
    if (disabledTransfers && disabledTransfers.length) {
      const transferString = type + "_" + pair;
      if (disabledTransfers.includes(transferString)) {
        return true;
      }
    }
  }
  return false;
};
/** ** CHECKING FOR ILS AND UAH TRANSFER FE PERMISSION */
/** NOTE: UAH transfers can only be either BANK or CARD
 * with both have transfer pair combo of EUR/UAH. */
export const isUahBankTransferAllowed = !isTransferDisabled(
  PaymentMode.BANK,
  "EUR/UAH"
);
export const isUahCardTransferAllowed = !isTransferDisabled(
  PaymentMode.CARD,
  "EUR/UAH"
);
/** NOTE: ILS transfers can only be either BANK or CARD
 * with both have transfer pair combo of EUR/ILS. */
export const isIlsBankTransferAllowed = !isTransferDisabled(
  PaymentMode.BANK,
  "EUR/ILS"
);
export const isIlsCardTransferAllowed = !isTransferDisabled(
  PaymentMode.CARD,
  "EUR/ILS"
);
