import { noop } from "lodash";
import { config, isLogRocketEnabled } from "@/shared/utils/config";

// noop is used only to prevent typescript errors
let LogRocket = { init: noop, identify: noop, startNewSession: noop };
let createPlugin;

/**
 * Only add to build if logrocket is enabled
 */
if (isLogRocketEnabled) {
  LogRocket = require("logrocket");
  createPlugin = require("logrocket-vuex");
}

export const logRocketInit = (): void => {
  if (isLogRocketEnabled) {
    LogRocket.init(config.logRocketId);
  }
};

export const logRocketIdentify = (uuid: string): void => {
  if (isLogRocketEnabled && uuid) {
    LogRocket.identify(uuid);
  }
};

export const logRocketNewSession = (): void => {
  if (isLogRocketEnabled) {
    LogRocket.startNewSession();
  }
};

export const logRocketVuexPlugin = isLogRocketEnabled
  ? [createPlugin(LogRocket)]
  : [];
