import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/LogoWithText.svg'


const _withScopeId = (n: any) => (_pushScopeId("data-v-772a2ebe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ml-10 non-authenticated-header__back" }
const _hoisted_2 = { class: "non-authenticated-header__image" }
const _hoisted_3 = {
  key: 0,
  src: _imports_0,
  class: "non-authenticated-header__logo px-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_a_layout_header = _resolveComponent("a-layout-header")!

  return (_openBlock(), _createBlock(_component_a_layout_header, { class: "non-authenticated-header px-0" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.isAuthenticated)
          ? (_openBlock(), _createBlock(_component_BackButton, {
              key: 0,
              onOnBackClick: _ctx.logoutUser,
              label: _ctx.t('Log out'),
              loading: _ctx.logoutLoading
            }, null, 8, ["onOnBackClick", "label", "loading"]))
          : (_openBlock(), _createBlock(_component_BackButton, {
              key: 1,
              onOnBackClick: _ctx.toHome,
              label: _ctx.t('Back home')
            }, null, 8, ["onOnBackClick", "label"]))
      ]),
      _createElementVNode("div", _hoisted_2, [
        (!_ctx.hideLogo)
          ? (_openBlock(), _createElementBlock("img", _hoisted_3))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}