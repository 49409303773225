import { i18nNumberFormat } from "@/plugins/i18n";
import takeCurrencySymbol from "@dynameyes/js-utils/takeCurrencySymbol";
import Big from "big.js";
import escapeRegExp from "lodash/escapeRegExp";
import trimEnd from "lodash/trimEnd";

export function getPreferredLanguage(): string {
  // temporarily set default language to eu
  // return i18n.global.locale.value || window.navigator.language || "en";
  return "cs";
}

/**
 * Get decimal separator for current user locale
 */
export function getDecimalSeparator(): string {
  const n = 1.1;
  return n.toLocaleString(getPreferredLanguage()).substring(1, 2);
}

/**
 * Get the thousand separator for current user locale
 */
export function getThousandSeparator(): string {
  const n = 11111.1; // 11 111.1
  return n.toLocaleString(getPreferredLanguage()).substring(2, 3);
}

/**
 * parses any localized number string to its computable format;
 * removes/replaces all localized decimal and thousand separators
 * (e.g. 12 123,1 --> 12123.1)
 */
export function toComputableNumFormat(input: string): string {
  return input
    .replace(getThousandSeparator(), "")
    .replace(getDecimalSeparator(), ".")
    .replace(" ", "");
}

/**
 * parses input with period/comma
 *
 * returns comma with prefix 0
 * (e.g. ,123 --> 0,123)
 */
export function getPrefixZero(input: string): string {
  return input
    .replace(".", `0${getDecimalSeparator()}`)
    .replace(getDecimalSeparator(), `0${getDecimalSeparator()}`);
}

/**
 * Parse a big decimal using locale
 */
export interface LocalisedBigParams {
  localisedBigDecimal: string;
  returnZeroOnInvalidInput?: boolean;
  decimalPlaces?: number;
  roundUp?: boolean;
}

export function parseLocalisedBigDec({
  localisedBigDecimal,
  returnZeroOnInvalidInput = false,
  decimalPlaces = 0,
  roundUp = false,
}: LocalisedBigParams): string | number {
  const thousandSeparatorRegex = new RegExp(
    escapeRegExp(getThousandSeparator()),
    "g"
  );

  try {
    /** separated rounded big num since
     * adding .round() function in return part
     * causes invalid function error
     */
    const roundedBigNum = new Big(
      trimEnd(
        localisedBigDecimal
          .replace(thousandSeparatorRegex, "")
          .replace(getDecimalSeparator(), "."),
        "."
      )
    )
      .round(decimalPlaces, Big.roundHalfUp)
      .toFixed(decimalPlaces);

    const bigNum = new Big(
      trimEnd(
        localisedBigDecimal
          .replace(thousandSeparatorRegex, "")
          .replace(getDecimalSeparator(), "."),
        "."
      )
    ).toFixed(decimalPlaces);

    return roundUp ? roundedBigNum : bigNum;
  } catch (e) {
    console.log("ERROR in parseLocalisedBigDec: ", e);
    return returnZeroOnInvalidInput ? "0" : NaN;
  }
}

/**
 * ==== BIG DECIMAL FUNCTIONS ====
 *
 * To preserve precision across different operations and locale formatting these
 * functions will be used for display related updates for numbers
 */

/**
 * Use this to format number string when locale is updated
 */
export function formatNumberLocaleDisplay(
  amount: string,
  previousLocale: string
): string {
  const currentLocale = getPreferredLanguage();
  if (currentLocale === previousLocale) return amount;
  const enThousandsMark = ",";
  const enDecimalMark = ".";
  const ruThousandsMark = " ";
  const ruDecimalMark = ",";
  if (previousLocale === "ru") {
    return amount
      .replace(ruDecimalMark, enDecimalMark)
      .replace(ruThousandsMark, enThousandsMark);
  } else if (currentLocale === "ru") {
    return amount
      .replace(enThousandsMark, ruThousandsMark)
      .replace(enDecimalMark, ruDecimalMark);
  }
  return amount;
}

const lang = getPreferredLanguage();

export type FormatAmountOptions = {
  /** Use symbol instead of the three-letter code (default=`true`) */
  useSymbol: boolean;
  /** Sets the currency code or symbol before the amount (default=`true`) */
  isPrefixed: boolean;
  locale: string;
  decimalPlaces: number;
  currencyCode?: string;
};

export const DEFAULT_FORMAT_AMOUNT_OPTIONS: FormatAmountOptions = {
  useSymbol: true,
  isPrefixed: true,
  locale: lang,
  decimalPlaces: 2,
};

/**
 * Utility function that formats a number. Can have currency using three-letter code.
 * @param amount Amount in number
 * @param _extraOptions Extra options to provide for formatting number.
 */
export function formatAmount(
  amount: number | string,
  _extraOptions: Partial<FormatAmountOptions> = {}
): string {
  const options: FormatAmountOptions = {
    ...DEFAULT_FORMAT_AMOUNT_OPTIONS,
    ..._extraOptions,
  };

  // Parse amount first to number type
  const parsedAmount = isNaN(Number(amount)) ? 0 : Number(amount);

  // Format: Number
  let formattedAmount = "0";

  formattedAmount = parsedAmount.toFixed(options.decimalPlaces);

  formattedAmount = i18nNumberFormat(parsedAmount, "decimal", lang);

  // Format: Currency (if provided currencyCode)
  if (options.currencyCode) {
    const currencySymbol = takeCurrencySymbol(
      options.currencyCode.toUpperCase()
    );
    const currencyString = options.useSymbol
      ? currencySymbol
      : options.currencyCode;

    formattedAmount = options.isPrefixed
      ? `${currencyString} ${formattedAmount}`
      : `${formattedAmount} ${currencyString}`;
  }

  return formattedAmount;
}

/**
 * Rounds off number
 * @param value
 * @param precision
 * @returns
 */
export const roundOffNumber = (value: number, precision: number): number => {
  const multiplier = 10 ** precision;
  return Math.round(value * multiplier) / multiplier;
};
