export const apiErrorCodes = {
  INVALID_AUTH_TOKEN: 101001,
  TWO_FACTOR_AUTH: 102013,
  NO_FILES_FOUND: 103002,
  NO_DOCUMENT_FOUND: 103001,
  INTERNAL_ERROR: 100001,
  INVALID_INPUT: 102001,
  NON_WHITE_LISTED: 21608,
  PO_DOES_NOT_EXIST: 104014,
  INSUFFICIENT_BALANCE: 107005,
  FILE_ALREADY_UPLOADED: 103005,
  INVALID_SHORTCODE: 103004,
  INVALID_FILE_EXTENSION: 102004,
  CRYPTO_EXCHANGE_DOES_NOT_EXIST: 109001,
  LIMIT_EXCEEDED: 107010,
  CARD_IS_ALREADY_VERIFIED: 110005,
  WAIT_BEFORE_RESENDING: 102008,
  CRYPTO_AMOUNT_LESS_THAN_ONE: 109007,
  UAH_TRANSFER_LESS_THAN_ONE: 113003,
  ILS_TRANSFER_LESS_THAN_ONE: 115003,
};

export const axiosApiCodes = {
  SUCCESS: 200,
  LOGIN_SUCCESS: 302,
};

export const regex = {
  email:
    "^(([^<>()\\[\\]\\.,;:\\s@\\']+(\\.[^<>()\\[\\]\\.,;:\\s@']+)*)|('.+'))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$",
  alphanumericWithSpace: "^[a-zA-Z0-9\\s]+$",
  internalIBAN: "^MT[0-9]{2}MCPI.+",
  decimalValue: "[0-9]+[0-9\\s]*((.|,)(\\s*[0-9])+)?",
  MABErrorCode: "\\s*code:\\s*-*\\s*[0-9]*\\s*",
  commaSeparator: "\\s*,+\\s*",
  formattedCardNumber: "^([0-9]|\\s){19,19}$", //eslint-disable-line
  floatNumber: /[+-]?\d+(\.\d+)?/g, //don't add "" here
  /**
   * A-Z matches any uppercase letter. a-z matches any lowercase letter.
   * 0-9 matches any digit. \s stands for “whitespace character”.
   * The * character matches zero or more occurrences of a particular pattern.
   * We add it after the square brackets to match any of the patterns
   * in the brackets as many times as possible.
   */
  otpNonNumeric: "[A-Za-z0-9\\s]*",
  otpNumericOnly: "[0-9\\s]*",
  /** Matches the payment transfer type from AccountStatement.info provided by BE */
  accountStatementInfoPaymentType: /;([\w\s]*(payment|transfer))/gi,
  /** Matches all non-alphanumeric or non-space characters */
  nonAlphaNumericOrSpace: /[^\w\s]/g,
  /**
   * Matches sold/bought currencies on exchange info (TransactionList query).
   * `g` flag disabled so string.match() can return all capture groups.
   */
  matchCurrencies: /fx deal ((sold) [\d.]+ (\w+)).*((bought) [\d.]+ (\w+))/i,
  /**
   * Matches any file with extensions (.jpg, .png, jpg). To specify file extensions, replace the second regex grouping:
   * i.e. fileNameWithExtensions.replace("\\w+", "jpg|png""
   */
  fileNameWithExtensions: "(.+)\\.(\\w+)",
  /** formats code to add a space on every 3-digits */
  addSpaceOnThreeDigits: "/(?!^)(?=(?:\d{3})+(?:\.|$))/gm", //eslint-disable-line
  allowedUploadFileTypes: "(image|application)\\/", // Used for KYC document upload to allow both image and PDF files
  historyInfoHeaderFilter: /\s*(Payment|Transfer)$/,
  latinChars: /^[a-zA-Z0-9@~`!@#$%^&*()_ =+\\\\';:"\\/?>.<,-]*$/i,
};
