/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { regex } from "@/shared/utils/constants";
import { isRegexMatch } from "@/shared/utils/validators/commonValidators";
import takeCurrencySymbol from "@dynameyes/js-utils/takeCurrencySymbol";
import Cleave from "cleave.js";
import isEmpty from "lodash/isEmpty";
import upperCase from "lodash/upperCase";

// https://github.com/nosir/cleave.js/blob/master/doc/phone-lib-addon.md#how-to-include-phone-lib-addon
// import phone lib add on for all countries
require("cleave.js/dist/addons/cleave-phone.i18n.js");

export const getNameInitials = function (fullName: string): string {
  if (fullName && !isEmpty(fullName)) {
    const extractedFUllName = fullName.split(" ");
    const firstInitial = extractedFUllName[0].charAt(0);
    const lastInitial =
      extractedFUllName[extractedFUllName.length - 1].charAt(0);
    return firstInitial + lastInitial;
  }
  /* Return empty string since we cannot
     return the fullname when we expect the initials */
  return "";
};

export const getFullName = (
  firstName = "",
  lastName = "",
  middleName = ""
): string => {
  return firstName.concat(` ${middleName} `).concat(`${lastName}`).trim();
};

export const getFullAddress = (
  addressLine1 = "",
  addressLine2 = "",
  city = "",
  postalCode = ""
): string => {
  return addressLine1
    .concat(addressLine2 ? `, ${addressLine2}` : "")
    .concat(city ? `, ${city}` : "")
    .concat(postalCode ? `, ${postalCode}` : "")
    .trim();
};

export const maskStringShowTail = function (
  originalString: string,
  tailNumber = 4 /* Number of last digits to show */,
  delimiters: string[] = [" "] /* characters excluded in masking */,
  maskValue = "*" /* mask character */
): string {
  const length = originalString.length;
  const visible = originalString.substr(length - (tailNumber + 1));
  let masked = originalString.substr(0, length - (tailNumber + 1));
  masked = masked.replace(
    new RegExp(`[^${delimiters.join("")}]`, "g"),
    maskValue
  );
  return masked + visible;
};

export const maskString = (originalString: string): string => {
  return originalString.replace(/./g, "*");
};

export const removeSpaces = (input: string): string => {
  return input ? input.replace(/\s/g, "") : "";
};

export const splitString = (str: string, separator: string): string[] => {
  return str.split(separator);
};

export const removeKeywords = (
  originalString: string,
  keywordsToFilter: string[]
) => {
  const regex = new RegExp(`(${keywordsToFilter.join("|")})`, "ig");
  return originalString.replace(regex, "");
};

export const replaceKeywords = (
  originalString: string,
  keywords: { keyword: string; replace: string }[]
) => {
  let updatedString = originalString;

  keywords.forEach((item) => {
    updatedString = updatedString.replace(item.keyword, item.replace);
  });

  return updatedString;
};

export const isAlphanumericOrSpace = (e) => {
  const char = String.fromCharCode(e.keyCode);
  if (isRegexMatch(char, regex.alphanumericWithSpace)) return true;
  else e.preventDefault();
};

export function getLastWordInStr(words: string) {
  if (isEmpty(words)) {
    return "";
  }
  const n = words.trim().split(" ");
  return n[n.length - 1];
}

export function omitLastWordInStr(words: string) {
  if (isEmpty(words)) {
    return "";
  }
  return words.trim().replace(/[\W]*\S+[\W]*$/, "");
}

/**
 * Extracting float numbers from string
 * e.g. hello 12.3
 * returns 12.3
 *
 * @param stringToExtract - string to extract float number from
 * @returns float number
 */
export function extractFloatNumbersFromString(stringToExtract) {
  return stringToExtract?.match(regex.floatNumber).map(function (v) {
    return parseFloat(v);
  });
}
/**
 * Fetching currency symbol based on given currency
 *
 * @param currencyCode - currency to fetch symbol
 * @returns currency symbol
 */
export function currencySymbolDisplay(currencyCode) {
  return takeCurrencySymbol(upperCase(currencyCode));
}

/** Setup cleave.js to format phone numbers */
const temporaryPhoneFormatterElement = document.createElement("input");
const phoneCleave = new Cleave(temporaryPhoneFormatterElement, {
  phone: true,
});

/** Tries to format a string to a phone number based on its country.
 * Returns original string if cannot format.
 * @param phoneNumber The phone number in string format.
 */
export const formatPhoneNumber = (phoneNumber: string) => {
  try {
    phoneCleave.setRawValue(phoneNumber);
    return phoneCleave.getFormattedValue();
  } catch (e) {
    console.error("Error in formatting phone number.", e);
    return phoneNumber;
  }
};
