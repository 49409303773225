import { ApolloLink } from "@apollo/client/core";
import { onError } from "apollo-link-error";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import isEmpty from "lodash/isEmpty";
import routeNames from "@/shared/router/routeNames";
import store from "@/store";
import { makeToast } from "@/shared/utils/toast";
import { i18nTranslate } from "@/plugins/i18n";
import { HideIfErrorCode } from "@/shared/utils/graphql/errorHandler";
import useLogging from "@/shared/composables/useLogging";
import debounce from "lodash/debounce";
import { config, isCorporateSite } from "@/shared/utils/config";
import { wsClientContainer } from "./wsLink";
import corporateRouter from "@/corporate/router";
import privateRouter from "@/private/router";
import { ServerError } from "apollo-link-http-common";

/**
 * Update this for to include all [__typename] for all errors that we
 * need to logout the user. For available [__typename] for all errors refer to the schema
 */
const errorTypeNameToLogout = ["AuthenticationError"];

export const customErrorHandlerLink = new ApolloLink((operation, forward) => {
  const router = isCorporateSite ? corporateRouter : privateRouter;

  const { submitInfoToApi } = useLogging();
  return forward(operation).map((data) => {
    const parsedGqlResponse = parseGqlResponse<void>(
      "",
      data?.data,
      HideIfErrorCode.ALL_ERRORS
    );

    if (!isEmpty(parsedGqlResponse?.error?.errors)) {
      /// Automatic logout based on custom error
      const shouldLogout = parsedGqlResponse?.error?.errors?.some((error) =>
        errorTypeNameToLogout.includes(error?.__typename)
      );

      if (shouldLogout && store?.state?.userInfo?.isAuthenticated) {
        (async () => {
          // close ws client connection
          wsClientContainer.wsClient?.close(true, true);

          // set to false so we can proceed to non-auth page
          store.state.userInfo.isAuthenticated = false;

          makeToast(
            "error",
            i18nTranslate("Error"),
            i18nTranslate(
              "You have been logout due to invalid session. The page will automatically refresh."
            )
          );
          await router.push({ name: routeNames.login });
          submitInfoToApi(
            "SESSION_EXPIRED: Logged Out Automatically due to inactivity"
          );
        })();
      }
    }

    return data;
  });
});

/**
 * Current suppression count for toast,
 * if networkError in `operation.input.context` is empty.
 * See: #862j2racr
 */
let createLogRetryCount = 0;
/** Max suppression tries. */
const CREATE_LOG_EMPTY_NETWORK_ERROR_MAX_RETRY_COUNT = 3;

/**
 * Debounced logging
 *
 * There's no other use for now
 * It is discouraged to use this method because we might lose important error logging
 * This is used only on network error when possibility of user internet error or server down time
 */
const useDebounceLogging = () => {
  const { submitErrorToApi } = useLogging();

  return {
    debouncedSubmitErrorToApi: debounce(submitErrorToApi, 5000),
  };
};

export const errorHandlerLink = onError(
  ({ graphQLErrors, networkError, operation }) => {
    const { submitErrorToApi } = useLogging();
    const { debouncedSubmitErrorToApi } = useDebounceLogging();

    /**
     * Add console log, this will be available on logrocket
     */
    console.group("errorHandlerLink");
    console.log(`graphQLErrors`, !!graphQLErrors);
    console.log(`networkError`, !!networkError);
    console.log(`errorResponse`, { graphQLErrors, networkError, operation });
    console.groupEnd();

    if (graphQLErrors) {
      submitErrorToApi("GRAPHQL_ERRORS", {
        graphQLErrors,
        networkError,
        operation,
      });
      makeToast(
        "error",
        i18nTranslate("Error"),
        i18nTranslate(
          "Something went wrong. Please try again later. If the problem persists, please contact us at {contactEmail}",
          {
            contactEmail: config.contactEmail,
          }
        )
      );
    } else if (networkError) {
      /**
       * If error thrown is this error again
       *
       * Do not proceed if error is "Failed to fetch - chrome" or "Load failed - safari"
       * - this means that the user do not have internet and logging will also fail
       */
      if (
        (networkError.message && networkError.message === "NETWORK_ERROR") ||
        (networkError.message && networkError.message === "Failed to fetch") ||
        (networkError.message && networkError.message === "Load failed") ||
        operation.operationName === "CreateLog"
      ) {
        return;
      }

      /**
       * Detect Large file size error
       *
       * error code occurs when the size of a client's
       * request exceeds the server's file size limit
       */
      if ((networkError as ServerError).statusCode === 413) {
        makeToast(
          "error",
          i18nTranslate("File Size Error"),
          i18nTranslate("Please upload smaller file sizes.")
        );
        return;
      }

      // This can re-trigger an error, which is handled above
      debouncedSubmitErrorToApi("NETWORK_ERROR", {
        graphQLErrors,
        networkError,
        operation,
      });

      // Only suppress toast when...
      if (
        // networkError is empty (this is what is internally done to `submitInfoToApi`)
        JSON.stringify(networkError) === "{}" &&
        // while less than max retry count
        createLogRetryCount < CREATE_LOG_EMPTY_NETWORK_ERROR_MAX_RETRY_COUNT
      ) {
        createLogRetryCount++;
        console.log(
          `===== Empty networkError. Suppress for now. Retry #${createLogRetryCount} =====`
        );
        return;
      }

      makeToast(
        "error",
        i18nTranslate("Error"),
        i18nTranslate(
          "A network error occurred, Kindly refresh and try again. If the problem persists, please contact us at {contactEmail}",
          {
            contactEmail: config.contactEmail,
          }
        )
      );
    }
  }
);
